.Main {
  text-align: center;
  background-image: url("./assets/curves_background_body.svg");
  background-size: 155rem, 195rem;
  background-position: -23rem 12rem, center 130%;
  background-repeat: no-repeat, no-repeat;
  padding: 2rem 7rem;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.connect-modal {
  max-width: 95ch;
  margin: auto;
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 2rem;
  max-height: 100vh;
  overflow-y: auto;
}

.wallet-options-container {
  display: grid;
  padding-top: 1rem;
  gap: 1.25rem;
  grid-template-columns: repeat(1, 1fr);
}

@media screen and (min-width: 38rem) {
  .wallet-options-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

.wallet-option {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 0.375rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: var(--bg-color-3);
  background-color: transparent;
  border-width: 0.1rem;
  border-style: solid;
  border-color: rgb(204, 204, 204);
  transition: all 300ms ease 0s;
}

.wallet-option > span {
  font-size: 1.2rem;
}

.wallet-option > img {
  width: 5rem;
  height: 5rem;
  padding: 0.5rem;
}

.wallet-option:hover {
  border-color: var(--bg-color-3);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.grid1 {
  background-color: rgb(2 136 209 / 90%);
  border-radius: 2rem;
}

.topbar-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 100px;
}

.logo {
  width: 8rem;
  height: auto;
}

p.thick {
  font-family: "SourceSansPro-Semibold";
  text-align: center;
  margin: 0px;
}

.topbar-box-left {
  justify-self: start;
}
.topbar-box-center {
  justify-content: center;
  align-self: center;
}

.topbar-box-right {
  justify-self: end;
}

@media (max-width: 768px) {
  .Main {
    padding: 1rem;
  }

  .topbar-container {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
    align-items: center;
  }

  .topbar-box-left {
    grid-row: 1;
    align-items: center;
    justify-self: center;
  }

  .topbar-box-center {
    grid-row: 2;
  }

  .topbar-box-right {
    grid-row: 3;
    align-items: center;
    justify-self: center;
  }
}
