.user-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    margin: 2rem;
    background-color: var(--bg-color-1);
  }
    
  .user-input {
    flex-grow: 2;
    border: none;
    /* padding: 1rem 1.5rem; */
    box-sizing: border-box;
    font-family: 'SourceSansPro-Black';
    font-size: var(--font-size-3);
  }
  
  button > span {
      font-size: 1.5rem;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type=number] {
    -moz-appearance: textfield;
    font-size: 1.75rem;

  }
  
  .user-input:focus {
    outline: none;
  }
  
  .max-button {
    border: 0.1rem solid var(--bg-color-3);
    margin: 0 1rem;
    background: white;
    color: var(--bg-color-3);
    font-weight: bold;
  }
  
  .cancel-confirm-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .cancel-confirm-container button {
    width: 49%;
  }
  
  @media only screen and (max-width: 768px) {
    .user-input {
      width:100%
    }
  
  }